import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
                fill="#0BAD4B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.97 4.97a.75.75 0 011.07 1.05L8.05 11.01a.75.75 0 01-1.08.02L4.324 8.384a.75.75 0 111.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 01.02-.022z"
                fill="#0BAD4B"
            />
        </svg>
    )
}

export default SvgComponent
