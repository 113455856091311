import React from 'react'
import CheckIcon from '../../styles/icons/check-circle'
import CheckSMIcon from '../../styles/icons/check-sm'

export default function Hero() {
    return (
        <section>
            <div className="container mx-auto pt-10 grid lg:grid-cols-hero md:grid-cols-hero xl:grid-cols-hero relative gap-4">
                <div className="self-center space-y-4">
                    <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14">
                        Looking for an<br />
                        Affordable Employee Monitoring Software?
                    </h1>
                    <ul className="grid gap-4">
                        <li className="flex items-center"><CheckIcon className="mr-3" />Price as low as $1.72 per user (easily the best in market)</li>
                        <li className="flex items-center"><CheckIcon className="mr-3" />Quick setup for office based and work from home employees</li>
                        <li className="flex items-center"><CheckIcon className="mr-3" />Monitor websites visited, applications usage, productive time and more</li>
                        <li className="flex items-center"><CheckIcon className="mr-3" />Discover features that fit your circumstances and give you full control</li>
                        <li className="flex items-center"><CheckIcon className="mr-3" />Forget about the stress of monitoring employee productivity — we take care of it!</li>
                    </ul>
                    <input role="button" value="Start your free trial now" type="submit" className="max-w-min font-semibold focus:outline-none text-white bg-primary text-xs h-12 px-7 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg" />
                    <ul className="flex lg:space-x-2 md:space-x-2  flex-wrap">
                        <li className="flex space-x-2 items-center">
                            <CheckSMIcon />
                            <span className="text-xs text-body-light">No credit card required</span>
                        </li>
                    </ul>
                </div>
                <img className="lg:justify-self-end md:justify-self-end" src="/hero-dashboard.svg"></img>
            </div>
        </section>
    )
}

