import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={11}
            height={8}
            viewBox="0 0 11 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.707.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L4 5.586 9.293.293a1 1 0 011.414 0z"
                fill="#0BAD4B"
            />
        </svg>
    )
}

export default SvgComponent
