import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import Monitoring from "../components/PageFeatures/Monitoring"
import Workforce from "../components/Workforce"
import Remote from "../components/Remote"
import Testimonials from "../components/Testimonials"
import Pricing from "../components/Pricing"
import '../styles/index.scss';


const Index = () => (
    <Layout>
        <SEO title="Home" />
        <Hero />
        {/* <Features /> */}
        <Testimonials />
        <Workforce />
        <Monitoring />
        <Remote />
        <Pricing />
    </Layout>
)

export default Index
